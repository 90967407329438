import { render, staticRenderFns } from "./PurchaseApproach.vue?vue&type=template&id=72bc4206&scoped=true"
import script from "./PurchaseApproach.vue?vue&type=script&lang=js"
export * from "./PurchaseApproach.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bc4206",
  null
  
)

export default component.exports