<template>
  <v-sheet
    class="
      px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4
      pt-xl-8 pt-lg-8 pt-md-8 pt-sm-8 pt-8
      pb-xl-16 pb-lg-16 pb-md-16 pb-sm-16 pb-16
    "
  >
    <v-row
      class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0"
      justify="center"
      dense
    >
      <v-col
        sm="8"
        cols="12"
        v-for="(certification, i) in certifications"
        :key="i"
        :xl="certification.width"
        :lg="certification.width"
        :md="certification.width"
      >
        <v-img :src="certification.img_src" style="margin-left: 20%;margin-right: 20%;"/>
        <div class="text-center" style="font-size: x-large;">
          {{ certification.subtitle }}
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "AboutCertificationShowCertification",
  data: () => ({
    certifications: [
    {
        img_src: require("@/assets/about_certification_img/A1-管局支撑单位23-25年.png"),
        subtitle: "网络和数据安全支撑单位",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/A2-互联网协会会员单位.png"),
        subtitle: "上海市互联网协会会员",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/A3.专委会成员单位.jpg"),
        subtitle: "专委会成员单位",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/A2.管局支撑单位21-23年.png"),
        subtitle: "网络安全支撑单位(21-23)年",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/A6.车联网会员单位23年.png"),
        subtitle: "车联网协会会员单位",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/A7.电子信息联合会会员单位.png"),
        subtitle: "电子信息联合会会员单位",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/B1.CCRC证书.png"),
        subtitle: "电子信息联合会会员单位",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/B7.CS等级证书.png"),
        subtitle: "信息系统建设和服务能力CS1级认证",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/A4.管局优秀支撑单位22年.png"),
        subtitle: "上海市通信管理局2022年网络安全优秀支撑单位",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/B4.中泰质量管理.png"),
        subtitle: "质量管理体系认证",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/B5.中泰环境管理.png"),
        subtitle: "环境管理体系认证",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/B6.中泰职业健康.png"),
        subtitle: "职业健康安全管理体系认证",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/B2.数安评估系统软著.png"),
        subtitle: "软著-数据安全评估系统",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/B3.映涵测试系统软著.png"),
        subtitle: "软著-映涵测试系统",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/C1.管局感谢信21年.png"),
        subtitle: "上海市通信管理局感谢信",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/C2.管局感谢信22年.png"),
        subtitle: "上海市通信管理局感谢信",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/C3.管局感谢信23年.png"),
        subtitle: "上海市通信管理局感谢信",
        display: "horizontal",
        width:"4",
      },
      {
        img_src: require("@/assets/about_certification_img/C4.管局突出集体个人通报23年.png"),
        subtitle: "上海市通信管理局突出集体通报",
        display: "horizontal",
        width:"6",
      },
      {
        img_src: require("@/assets/about_certification_img/C5.互联网协会感谢信23年.png"),
        subtitle: "上海市通信管理局感谢信",
        display: "horizontal",
        width:"6",
      },
    ],
  }),
};
</script>

<style scoped>
</style>